import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import "../EnterPaymentForm.css";
import { logErrorToNode } from "../errorLogger.js";
const hostURL = process.env.REACT_APP_MODE === "DEV" ? process.env.REACT_APP_DEV_HOST : process.env.REACT_APP_PROD_HOST;

function EnterPaymentForm({ Stripe_Customer_ID, orderId, contractorId, isFileMaker }) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);


  let performFM = (data) => {
    if (isFileMaker) {
      try {
      // eslint-disable-next-line
        let test = FileMaker.PerformScript("Stripe | Refresh Payment Method Panel", data);
        console.log("FileMaker test is: ", test);

      } catch (error) {
        console.log("FileMaker Error is: ", error)
      }
    } else {
      console.log("0 - FileMaker Error is: ", error)
    }

  }


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      setError("Stripe.js has not loaded. Please try again later.");
      return;
    }

    const cardElement = elements.getElement(CardElement);
    // const Stripe_Customer_ID = Stripe_Customer_ID ;

    if (!Stripe_Customer_ID || Stripe_Customer_ID === null) {
      console.log("missing customer ID")
      setError(`Customer ID is missing`);
      return
    }


    if (contractorId === "1" && !orderId) {
      setError(`Order ID is missing`);
      return;
    }

    try {
      const { paymentMethod, error: stripeError } = await stripe.createPaymentMethod(
        {
          type: 'card',
          card: cardElement,
          billing_details: {
            name: '',
          },
          metadata: {
            orderId: orderId,
            contractorId: contractorId,
          }
        }
      );

      console.log("paymentMethod is: ", paymentMethod)
      console.log("stripeError is: ", stripeError)
      console.log("error is: ", error)

      if (stripeError) {

        let error = {
          stripeError: stripeError,
          Stripe_Customer_ID: Stripe_Customer_ID,
          orderId: orderId,
          contractorId: contractorId
        }
        performFM(error)

        logErrorToNode(error);
        setError(`Stripe error: ${stripeError.message}`);
        return;
      }


      try {
        //If customer already has a STRIPE ID
        const attachPaymentMethod = await fetch(hostURL + '/payment/attach-payment-method', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(
            {
              Stripe_PaymentMethod_ID: paymentMethod.id,
              Stripe_Customer_ID: Stripe_Customer_ID,
            }),
        });

        const data = await attachPaymentMethod.json();
        console.log("attachPaymentMethod is: ", attachPaymentMethod)
        console.log("data is: ", data);

        performFM(JSON.stringify(data))

        if (!attachPaymentMethod.ok) {
          const errorMessage = data.raw.message || 'Failed to attach the payment method. Please try again.';
          setError(errorMessage);
          performFM(JSON.stringify(data))

          return;
        };

        // If everything is successful, update the state to reflect payment success
        setPaymentSuccess(true);
        setError(null);
        cardElement.clear(); // Clears the card input
        elements.getElement(CardElement).clear(); // Alternatively clear again if needed


      } catch (error) {
        performFM(error)
        console.log("error is: ", error)
      }



    } catch (err) {
      let error = {
        Message: err.message,
        Stripe_Customer_ID: Stripe_Customer_ID,
        orderId: orderId,
        contractorId: contractorId
      }
      performFM(err)
      logErrorToNode(error);
      setError(err.message);
    }
  };

  return (
    <section className="hero container">

      <section className="paymentMethod-container">
        <div className="hero-top">
          {/* <h4>Payment Information</h4> */}
          {/* <h5>Please remain on this page after confirming the payment.</h5>
        <h5>Some cards will require futher action for authentication.</h5> */}
        </div>
        <div className="form-container">
          {/* <h2>Enter Your Payment Details</h2> */}
          <form className="form" onSubmit={handleSubmit}>
            <CardElement />
            <button className="PayMethodBtn" type="submit" disabled={!stripe}>
              Save
            </button>
            {error && <div style={{ color: 'red' }}>{error}</div>}
            {paymentSuccess && <div style={{ color: 'green' }}>Payment Method Successfully created!</div>}
          </form>
        </div>
      </section>
    </section>);
}

export default EnterPaymentForm;
