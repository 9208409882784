import React from 'react';

function Header() {
  return (
    <header id="header" className="menu-container">
      <img src="/Niagara-Logo.png" className="header-img" alt="logo" />
      {/* <nav id="nav-bar">
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label className="menu-icon"><span className="nav-icon"></span></label>
        <ul className="menu">
        </ul>
      </nav> */}
    </header>
  );
}

export default Header;
