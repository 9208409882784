import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';

const stripe_api_key = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(stripe_api_key); 
const hostURL = process.env.REACT_APP_MODE === "DEV" ? process.env.REACT_APP_DEV_HOST : process.env.REACT_APP_PROD_HOST;

function PaymentForm({ Stripe_PaymentIntent_ID, amount, paymentData, error, setThreeDSApproved, setLoading, setConfirmBtnClicked }) {
  // const [modalOpen, setModalOpen] = useState(false);
  // const [modalMessage, setModalMessage] = useState('');
  // const [modalTitle, setModalTitle] = useState('');


  useEffect(() => {
    console.log("Payment Intent ID:", Stripe_PaymentIntent_ID);
    console.log("Amount:", amount);
    console.log("paymentData:", paymentData);

  }, [Stripe_PaymentIntent_ID, amount, paymentData]);

  const handleCloseModal = () => {
    // setModalOpen(false);
  };


  // Write payment amount to submit button
  // var submitBtn = document.getElementById('submitBtn');
  // submitBtn.innerText = 'Confirm for $' + paymentAmount;


  const handleSubmit = async (event) => {
    event.preventDefault();

    const stripe = await stripePromise;
    setConfirmBtnClicked(1)
    // const Stripe_PaymentIntent_ID = "pi_3PqKVRHFQ58XEpsT1pvnmV91";

    if (!Stripe_PaymentIntent_ID) {
      // modalResponse('Error', 'Payment Intent ID is required.')
      return;
    }

    try {
      const retrievePaymentIntent = await fetch(hostURL + '/payment/retrievePaymentIntent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Stripe_PaymentIntent_ID: Stripe_PaymentIntent_ID }),
      });


      const data = await retrievePaymentIntent.json();

      if (retrievePaymentIntent.ok) {

        if (data.status === "requires_action") {

          const { error: actionError, paymentIntent } = await stripe.handleCardAction(data.client_secret);
          console.log("paymentIntent is: ", paymentIntent)
          console.log("actionError is: ", actionError)

          if (paymentIntent !== undefined) {
            if (paymentIntent.status === "requires_confirmation") {

              try {
                const confirmPaymentIntent = await fetch(hostURL + '/payment/confirm-payment-intent', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ Stripe_PaymentIntent_ID: Stripe_PaymentIntent_ID }),
                });

                if (confirmPaymentIntent.status === 200) {
                  setLoading(false);
                  setThreeDSApproved("Success"); // Update state in the parent

                } else {
                  setLoading(false);
                  setThreeDSApproved("Failed"); // Update state in the parent
                }

              } catch (error) {
                setLoading(false);
                setThreeDSApproved("Failed"); // Update state in the parent
              }

            } else {
              setLoading(false);
              setThreeDSApproved("Failed"); // Update state in the parent
            }
          } else {
            if (actionError.code === "payment_intent_authentication_failure") {
              setLoading(false);
              setThreeDSApproved("Failed"); // Update state in the parent
            } else {
              setLoading(false);
              setThreeDSApproved("Failed"); // Update state in the parent
              return;
            }
          }



        } else if (data.status === 'succeeded') {
          setLoading(false);
          setThreeDSApproved("Success"); // Update state in the parent

        } else if (data.last_payment_error) {
          if (data.last_payment_error.code === "payment_intent_authentication_failure") {
          }
        } else {
          setLoading(false);
          setThreeDSApproved("Failed"); // Update state in the parent
        }
      } else {
        setLoading(false);
        setThreeDSApproved("Failed"); // Update state in the parent
      }
    } catch (error) {
      setLoading(true)
    }
  };

  return (
    <section className="hero container">
      <h1 className="hero-title-primary">Payment Information</h1>
      <p className="hero-title-sub"></p>

      <section className="subscribe-container">
        <div className="hero-top">
          <h4>Card Information</h4>
          <br></br>
          {/* <h5>Please remain on this page after confirming the payment.</h5>
        <h5>Some cards will require futher action for authentication.</h5> */}
        </div>
        <div className="form-container">

          <form id="form" method="post" onSubmit={handleSubmit}>
            <input className="ccForm ccField" type=""  name="ccNum" id="ccNum" value={`XXXX-XXXX-XXXX-${paymentData.last4}`} readOnly/>
            <input className="ccForm ccField" type=""  name="ccExp" id="ccExp" value={`${paymentData.exp_month}/${paymentData.exp_year}`} readOnly/>
            {/* <input className="ccForm" type=""  name="ccCVC" id="ccCVC" value={paymentData.display_brand} readOnly/> */}
            <div>
            <input className="ccForm ccField" type="submit" id="submit" value={`Confirm $${paymentData.amount / 100 }`} />
            </div>
            
          </form>

          
        </div>

        <div className="conditions">
            <h5>Payment Authorization and Acknowledgment</h5>
              <h6 className="p-conditions">By submitting your payment, you authorize  Niagara Concrete to process a pre-authorization charge on your credit card using 3D Secure verification. This pre-authorization charge will hold the estimated amount for your order. The final amount may change based on the actual pour, and you may be charged additional funds or receive a partial refund depending on the final volume of concrete delivered.</h6>
              <h6 className="p-conditions">By completing this process, you confirm that you are the authorized cardholder and approve this pre-authorization charge. The final settlement will occur after the pour, and you will be notified of any adjustments.</h6>
            </div>
      </section>
    </section>

  );
}

export default PaymentForm;