import './App.css';
import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Waves from './components/Waves';
import PaymentForm from './components/PaymentForm';
import { Router, Route, Routes, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import EnterPaymentForm from './components/EnterPaymentForm';
import BeatLoader from "react-spinners/BeatLoader";

const stripe_api_key = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(stripe_api_key);
const hostURL = process.env.REACT_APP_MODE === "DEV" ? process.env.REACT_APP_DEV_HOST : process.env.REACT_APP_PROD_HOST;

console.log("hostURL is: ", hostURL)
function App() {
  const [threeDSApproved, setThreeDSApproved] = useState('');
  const [paymentData, setPaymentData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [confirmBtnClicked, setConfirmBtnClicked] = useState(null);


  const queryParameters = new URLSearchParams(window.location.search)
  const Stripe_PaymentMethod_ID = queryParameters.get("paymentMethodId")
  const Stripe_PaymentIntent_ID = queryParameters.get("paymentIntentId")
  const Stripe_Customer_ID = queryParameters.get("customerId")
  const isFileMaker = queryParameters.get("filemaker")


  const orderId = queryParameters.get("orderId") ? queryParameters.get("orderId") : null;
  const contractorId = queryParameters.get("contractorId") ? queryParameters.get("contractorId") : null;
  const amount = queryParameters.get("amount")

  const location = useLocation();

  let [color, setColor] = useState("#FFFFFF");

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#FFFFFF",
  };


  // useEffect will only run once if Stripe_PaymentMethod_ID and Stripe_PaymentIntent_ID are stable.
  useEffect(() => {
    if (location.pathname === "/payment") {
    if (Stripe_PaymentMethod_ID && Stripe_PaymentIntent_ID && location.pathname) {
      // Function to send the click tracking request

      const trackClick = () => {
        try {
          fetch(hostURL + '/clickTracking', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ Stripe_PaymentMethod_ID, Stripe_PaymentIntent_ID, orderId }),
          }).catch((err) => {
            // Silently catch any errors
            console.log("Error in trackClick is: ", err);
          });
        } catch (error) {
          console.log("Error while running trackClick:", error);
        }
      };
      // Call the trackClick function without awaiting it
      trackClick();

      const fetchPaymentIntent = async () => {
        try {
          const verifyPaymentIntent = await fetch(
            hostURL + '/payment/verifyPaymentIntent',
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ Stripe_PaymentIntent_ID, Stripe_PaymentMethod_ID }),
            }
          );

          const paymentIntentData = await verifyPaymentIntent.json();

          if (!verifyPaymentIntent.ok) {
            if (
              paymentIntentData.message ===
              "Error: Payment Method ID does not match the Payment Intent."
            ) {
              setError("PM not matching");
            } else {
              setError(paymentIntentData.message);
            }
          } else {


            if (paymentIntentData.status === "succeeded") {
              setLoading(false);
              setThreeDSApproved("Success");
            } else if (paymentIntentData.last_payment_error !== null) {
              if (
                paymentIntentData.last_payment_error.code ===
                "payment_intent_authentication_failure"
              ) {
                setThreeDSApproved("Failed");
              } else {
                setThreeDSApproved("Failed");
              }
            } else {
              const retrievePaymentMethod = await fetch(hostURL + '/payment/retrievePaymentMethod', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ Stripe_PaymentMethod_ID }),
              })

              const paymentMethodData = await retrievePaymentMethod.json();
              // console.log("paymentIntentData is: ", paymentIntentData);


              setPaymentData({
                last4: paymentMethodData.card.last4,
                display_brand: paymentMethodData.card.display_brand,
                exp_month: paymentMethodData.card.exp_month,
                exp_year: paymentMethodData.card.exp_year,
                amount: paymentIntentData.amount,
              });
            };
          };


        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }

      };

      fetchPaymentIntent();



    } else {
      setLoading(false); // No IDs means no fetching, so we stop loading.
      setError("Error, payment IDs are missing")
    }
  } else {
    setLoading(false); // No IDs means no fetching, so we stop loading.
  }
  }, []);




  function PaymentFormWithParams() {

    // Conditional rendering based on loading, error, and threeDSApproved
    if (loading) {
      return (
        <section className="hero container">
          <section className="loading">
            <div className="">
              <BeatLoader
                className=""
                color={color}
                loading={loading}
                css={override}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
            <div className=""></div>
          </section>
        </section>
      );
    }

    if (error) {
      console.log("Error in Pay Form: ", error)
      if (error === "PM not matching") {
        return (
          <section className="hero container">
            <h1 className="hero-title-primary"></h1>
            <p className="hero-title-sub"></p>
            <section className="subscribe-container">
              <div className="hero-top">
                <h4>Sorry, the payment verification has failed.</h4>
                <h4>PM not matching</h4>
                {/* <h4>The payment method does not match the payment intent.</h4> */}
                <h5>Please contact Niagara. Thank you!</h5>
              </div>
              <div className="form-container"></div>
            </section>
          </section>
        );
      } else {
        return (
          <section className="hero container">
            <h1 className="hero-title-primary"></h1>
            <p className="hero-title-sub"></p>
            <section className="subscribe-container">
              <div className="hero-top">
                <h4>{error}</h4>
                {/* <h4>The payment method does not match the payment intent.</h4> */}
                <h5>Please contact Niagara. Thank you!</h5>
              </div>
              <div className="form-container"></div>
            </section>
          </section>
        );
      }
    }

    if (threeDSApproved === "Success") {
      return (
        <section className="hero container">
          <h1 className="hero-title-primary"></h1>
          <p className="hero-title-sub"></p>
          <section className="subscribe-container">
            <div className="hero-top">
              <h4>This payment has been approved.</h4>
              <h5>Thank you!</h5>
            </div>
            <div className="form-container"></div>
          </section>
        </section>
      );
    } else if (confirmBtnClicked === 1 && threeDSApproved === "") {
  
      setLoading(true);

      } else if (threeDSApproved === "Failed") {
      return (
        <section className="hero container">
          <h1 className="hero-title-primary"></h1>
          <p className="hero-title-sub"></p>
          <section className="subscribe-container">
            <div className="hero-top">
              <h4>Sorry, the payment verification has failed.</h4>
              <h5>Please contact Niagara. Thank you!</h5>
            </div>
            <div className="form-container"></div>
          </section>
        </section>
      );
    }


    

    

    return (
      <PaymentForm
        Stripe_PaymentIntent_ID={Stripe_PaymentIntent_ID}
        amount={amount}
        paymentData={paymentData}
        error={error}
        setThreeDSApproved={setThreeDSApproved} // Pass down the setter function
        setLoading={setLoading}
        setConfirmBtnClicked={setConfirmBtnClicked}
      />
    );
  };

  function EnterPaymentFormWithParams() {


    // Conditional rendering based on loading, error, and threeDSApproved
    if (loading) {
      return (


        <section className="hero container">
          <section className="loading">
            <div className="">
              <BeatLoader
                className=""
                color={color}
                loading={loading}
                css={override}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
            <div className=""></div>
          </section>
        </section>
      );
    }

    if (error) {
      return <div>Error: {error}</div>;
    }


    return (

      <Elements stripe={stripePromise}>
        <EnterPaymentForm
          Stripe_Customer_ID={Stripe_Customer_ID}
          error={error}
          stripe={stripePromise}
          orderId={orderId}
          contractorId={contractorId}
          isFileMaker={isFileMaker}
        />
      </Elements>

    );
  };

  return (
    <div className="App">
      {location.pathname === "/payment" && <Header />}
      <main className="container">
        <Routes>
          <Route path="/payment" element={<PaymentFormWithParams />} />
          <Route path="/enter-payment" element={<EnterPaymentFormWithParams />} />
        </Routes>
      </main>
      {location.pathname === "/payment" && <Waves />}
    </div>
  );
}
export default App;




